.container-flex {
    display: flex;
    flex-wrap: nowrap;
}

.logo img {
    max-height: 60px;
}

.olive-end {
    display: flex;
    justify-content: flex-end;
}

.olive-end img {
    width: 100%;
}

.thumbnail img {
    box-shadow: none;
}

.container-grid {
    display: grid;
    grid-template-columns: 5% 40% 40% 15%;
    max-width: 100%;
}

.grid-1 {
    display: flex;
    justify-content: center;
}

.section-title {
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.thumbnail-grid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-grid {
    padding: 0;
}

.title-h2 {
    color: rgba(147, 183, 83, 1);
}

.service-area {
    background-color: rgba(248, 234, 194, 1);
}

.service-one-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.row-around {
    display: flex;
    flex-wrap: nowrap;
}

.container-service {
    display: grid;
    max-width: 100% !important;
    grid-template-columns: 10% 60% 30%;
}

.img-content {
    display: flex;
    /* align-items: flex-end; */
    justify-content: end;
}

.icon-main,
.icon-text-main {
    color: rgba(167, 183, 138, 1) !important;
}

.rn-counterup-area {
    background-color: rgba(147, 183, 83, 1);
}

.row-numb {
    padding: 50px;
}

.grid-template {
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

.counter-img-content-bottom {
    display: flex;
    justify-content: end;
    position: relative;
}

.counter-img-content-bottom img {
    position: absolute;
    top: 100px;
    z-index: 99;
}

.container-counter {
    max-width: 100%;
    padding: 100px 0;
}

.counter-img-content-top {
    position: relative;
}

.counter-img-content-top img {
    position: absolute;
    bottom: 120px;
}

.vector {
    display: flex;
    justify-content: end;
    width: 60%;
    margin: 0 auto;
    position: relative;
}

.vector img {
    position: absolute;
    top: 85px;
    z-index: 99;
}

.container-blog {
    max-width: 100%;
    padding: 0;
}

.blog-component {
    display: grid;
    grid-template-columns: 10% 80% 10%;
}

.blog-img-component img {
    position: absolute;
    top: 0;
}

.rn-blog-area {
    position: relative;
}

.container-partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-partners h1 {
    color: rgba(147, 183, 83, 1);
}

.container-brand {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    position: relative;
}

.brand-img-content img {
    position: absolute;
    bottom: -120px;
}

.last-olive-img {
    display: flex;
    justify-content: end;
    align-items: flex-end;
}

.footer-style-2 {
    background-color: rgba(45, 56, 24, 1) !important;
}

/* contact */

.contact-main {
    background-color: rgba(45, 56, 24, 1);
}

.contact-text {
    color: rgba(121, 132, 100, 1);
    font-size: 54px !important;
    padding: 120px;
}

.information-title {
    color: rgba(147, 183, 83, 1);
}

.rn-contact-top-area {
    position: relative;
}

.breadcrumb-area {
    background-color: rgba(45, 56, 24, 1);
}

.portfolio-area {
    position: relative;
}

.portfolio-img-content,
.blog-img-content,
.vector-contact,
.vector-head {
    position: absolute;
    top: -15px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: end;
}

.rn-about-area {
    position: relative;
}

.container-path {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.path-left {
    background-color: yellow;
    height: 501px;
    width: 261px;
    clip-path: polygon(0 18%, 100% 0, 100% 100%, 13% 100%);
}

.path-right {
    background-color: lime;
    height: 464px;
    width: 231px;
    clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 100%);

}

a.rn-btn {
    border: 2px solid white;
}

.slide-style-2 {
    background-color: black
}

.about-area {
    position: relative;
}

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.service__style--2:hover .icon-text-main {
    color: white !important;
}

@media screen and (max-width:768px) {

    .container-grid,
    .container-service,
    .grid-template,
    .blog-component,
    .container-brand {
        display: block;
    }

    .olive-end,
    .img-content,
    .counter-img-content-bottom,
    .counter-img-content-top,
    .blog-img-component,
    .blog-img-component,
    .blog-img-component,
    .brand-img-content img,
    .last-olive-img,
    .portfolio-img-content,
    .blog-img-content,
    .vector-contact,
    .vector-head,
    .vector {
        display: none;
    }

    .section-title {
        max-width: 100%;
    }

    .col-lg-12 {
        padding: 0;
        max-width: 100%;
    }

    .breadcrumb-area .container {
        width: 100%;
    }
    .contact-main .container{
        width: 100%;
    }
    .contact-text{
        padding: 60px;
    }
}